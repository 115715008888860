.input-describer {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.full-centering {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.equal-distribution {
  display: flex;
  flex-direction: column;
}

.display-text-group {
  text-align: justify;
}

.read-more-button {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  padding-left: 5px;
  font-weight: bold;
}

.long-text-font {
  font-size: 1rem;
  text-align: justify;
}

.status-error-text {
  font-weight: bold;
  color: #d5d5d5;
  font-size: 14rem;
}

.error-text {
  padding-bottom: 20px;
  font-size: 1rem;
  text-align: justify;
}

.row {
  &:before {
    display: none;
  }

  &:after {
    display: none;
  }
}

.preloader-big {
  width: 10rem;
  height: 10rem;
  border-width: 1rem;
}
