#app {
  position: absolute;
  width: 100%;
  height: 100%;
}
.appWrapped {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
  justify-content: space-between;
}

.notFound {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-content: center;
  justify-items: center;
  flex-direction: column;
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.titlePaymentWillBe {
  margin-top: 116px;
  text-align: center;
}
.descriptionPaymentWillBe {
  color: #bebebe;
  text-align: center;
}
