.o-background {
  position: absolute;
  max-width: 65%;
  max-height: 65%;
  z-index: -1;
  &--error {
    right: 10%;
    top: 10%;
    max-width: 75%;
    max-height: 75%;
    opacity: 0.5;
  }
}

.pse_container {
  display: flex;
  align-items: center;
}

.daviplata {
  &_logo {
    width: 15%;
    padding-left: 2%;
  }
  &_footer {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
}

// Estilos para card de referencias de pago para efectivo
.cashPayment {
  &__card {
    border: 1px solid rgba(0,0,0,.125);
    background: #fff;
    border-radius: .25rem;
    max-height: 80px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)
  }
  &__infoData {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .pse {
    &_cancel {
      order: 1;
      width: 100%;
    }
    &_pay {
      order: 2;
      width: 100%;
    }
    &_img {
      display: flex;
      justify-content: center;
      order: 3;
    }
  }
  .daviplata {
    &_logo {
      width: 20%;
    }
    &_footer {
      width: 85%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .pse {
    &_cancel {
      order: 2;
      width: 100%;
    }
    &_pay {
      order: 1;
      width: 100%;
    }
    &_img {
      display: flex;
      justify-content: center;
      order: 3;
    }
  }
  .daviplata {
    &_logo {
      width: 30%;
    }
    &_footer {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &_cancel {
      width: 100%;
    }
    &_pay {
      width: 100%;
    }
  }
}
