.enlace {
    display:inline;
    border:0;
    padding:0;
    margin:0;
    // text-decoration:underline;
    background:none;
    color:#2990ff;
    font-family: arial, sans-serif;
    font-size: 1em;
    line-height:1em;
    cursor:pointer;

  }
// .enlace:hover {
//     text-decoration:none;
//     color:#2990ff;
//     cursor:pointer;
//   }