// Tables
table {
  th {
    font-size: $table-th-font-size;
    font-weight: 400;
  }
  td {
    font-size: $table-td-font-size;
    font-weight: 300;
  }
  &.table {
    thead th {
      border-top: none;
    }
    th,
    td {
      padding-top: $table-th-padding-top;
      padding-bottom: $table-td-padding-bottom;
    }
    a {
      margin: 0;
      color: $table-a-color;
    }
    .label-table {
      margin: 0;
      padding: 0;
      line-height: $table-label-height;
      height: $table-label-line-height;
    }
    &.btn-table {
      td {
        vertical-align: middle;
      }
    }
  }
  &.table-hover {
    tbody {
      tr {
        &:hover {
          transition: $table-hover-transition;
          background-color: $table-hover-background-color;
        }
      }
    }
  }
  .th-lg {
    min-width: $table-th-lg-min-width;
  }
  .th-sm {
    min-width: $table-th-sm-min-width;
  }
  &.table-sm {
    th,
    td {
      padding-top: $table-sm-padding-y;
      padding-bottom: $table-sm-padding-y;
    }
  }
}
.table-scroll-vertical {
  max-height: $table-scroll-vertical-max-height;
  overflow-y: auto;
}
.table-fixed {
  table-layout: fixed;
}
.table-responsive,
.table-responsive-sm,
.table-responsive-md,
.table-responsive-lg,
.table-responsive-xl {
  > .table-bordered {
    border-top: 1px solid #dee2e6;
  }
}
